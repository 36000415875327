import React from 'react'

import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import PortraitGrid from '../../components/portraitGrid'

const PortraitsPage = props => {
  const portraitData = [
    {
      img: props.data.image1.childImageSharp.fluid,
      cols: 2,
      name: 'Damon B.',
    },
    {
      img: props.data.image2.childImageSharp.fluid,
      cols: 2,
      name: 'Astro "Three Times" M.',
    },
    {
      img: props.data.image3.childImageSharp.fluid,
      cols: 1,
      name: 'Daniel T.',
    },
    {
      img: props.data.image4.childImageSharp.fluid,
      cols: 1,
      name: 'Jim S.',
    },
    {
      img: props.data.image5.childImageSharp.fluid,
      cols: 1,
      name: 'Unknown',
    },
    {
      img: props.data.image6.childImageSharp.fluid,
      cols: 1,
      name: 'Jerry W.',
    },
    {
      img: props.data.image7.childImageSharp.fluid,
      cols: 4,
      name: 'Unknown',
    },
    {
      img: props.data.image8.childImageSharp.fluid,
      cols: 1,
      name: 'Aziga W.',
    },
    {
      img: props.data.image9.childImageSharp.fluid,
      cols: 2,
      name: 'Larissa T.',
    },
    {
      img: props.data.image10.childImageSharp.fluid,
      cols: 1,
      name: 'Dan K.',
    },
    {
      img: props.data.image11.childImageSharp.fluid,
      cols: 2,
      name: 'Unknown',
    },
    {
      img: props.data.image12.childImageSharp.fluid,
      cols: 2,
      name: 'Unknown',
    },
    {
      img: props.data.image13.childImageSharp.fluid,
      cols: 1,
      name: 'Maria M.',
    },
    {
      img: props.data.image14.childImageSharp.fluid,
      cols: 1,
      name: 'Cabrina J.',
    },
    {
      img: props.data.image15.childImageSharp.fluid,
      cols: 1,
      name: 'Unknown',
    },
    {
      img: props.data.image16.childImageSharp.fluid,
      cols: 1,
      name: 'John D.',
    },
    {
      img: props.data.image17.childImageSharp.fluid,
      cols: 4,
      name: 'Unknown',
    },
    {
      img: props.data.image18.childImageSharp.fluid,
      cols: 2,
      name: 'Omar D.',
    },
    {
      img: props.data.image19.childImageSharp.fluid,
      cols: 2,
      name: 'Bear L.',
    },
    {
      img: props.data.image20.childImageSharp.fluid,
      cols: 1,
      name: 'Unknown',
    },
    {
      img: props.data.image21.childImageSharp.fluid,
      cols: 1,
      name: 'Keith M.',
    },
    {
      img: props.data.image22.childImageSharp.fluid,
      cols: 1,
      name: 'Unknown',
    },
    {
      img: props.data.image23.childImageSharp.fluid,
      cols: 1,
      name: 'Ken T.',
    },
    {
      img: props.data.image24.childImageSharp.fluid,
      cols: 2,
      name: 'Deszi G.',
    },
    {
      img: props.data.image25.childImageSharp.fluid,
      cols: 2,
      name: 'Clifford M.',
    },
    {
      img: props.data.image26.childImageSharp.fluid,
      cols: 4,
      name: 'Leroy B.',
    },
    {
      img: props.data.image27.childImageSharp.fluid,
      cols: 1,
      name: 'Leah Z.',
    },
    {
      img: props.data.image28.childImageSharp.fluid,
      cols: 1,
      name: 'Unknown',
    },
    {
      img: props.data.image29.childImageSharp.fluid,
      cols: 2,
      name: 'Unknown',
    },
    {
      img: props.data.image30.childImageSharp.fluid,
      cols: 2,
      name: 'Eric N.',
    },
    {
      img: props.data.image31.childImageSharp.fluid,
      cols: 1,
      name: 'Michelle T.',
    },
    {
      img: props.data.image32.childImageSharp.fluid,
      cols: 1,
      name: 'Unknown',
    },
    {
      img: props.data.image33.childImageSharp.fluid,
      cols: 4,
      name: 'Mona R.',
    },
    {
      img: props.data.image34.childImageSharp.fluid,
      cols: 2,
      name: 'Unknown',
    },
    {
      img: props.data.image35.childImageSharp.fluid,
      cols: 2,
      name: 'Mr. Williams',
    },
    {
      img: props.data.image36.childImageSharp.fluid,
      cols: 2,
      name: 'Myron N.',
    },
    {
      img: props.data.image37.childImageSharp.fluid,
      cols: 2,
      name: 'Unknown',
    },
    {
      img: props.data.image38.childImageSharp.fluid,
      cols: 1,
      name: 'Unknown',
    },
    {
      img: props.data.image39.childImageSharp.fluid,
      cols: 2,
      name: 'Unknown',
    },
    {
      img: props.data.image40.childImageSharp.fluid,
      cols: 1,
      name: 'Hailey M.',
    },
    {
      img: props.data.image41.childImageSharp.fluid,
      cols: 2,
      name: 'Amber H.',
    },
    {
      img: props.data.image42.childImageSharp.fluid,
      cols: 1,
      name: 'Corrina G.',
    },
    {
      img: props.data.image43.childImageSharp.fluid,
      cols: 1,
      name: 'Brittian "Captain Jack" S.',
    },
    {
      img: props.data.image44.childImageSharp.fluid,
      cols: 4,
      name: 'Mrs. Williams',
    },
    {
      img: props.data.image45.childImageSharp.fluid,
      cols: 2,
      name: 'Travis N.',
    },
    {
      img: props.data.image46.childImageSharp.fluid,
      cols: 2,
      name: 'Unknown',
    },
    {
      img: props.data.image47.childImageSharp.fluid,
      cols: 4,
      name: 'Justin A.',
    },
    {
      img: props.data.image48.childImageSharp.fluid,
      cols: 1,
      name: 'Unknown',
    },
    {
      img: props.data.image49.childImageSharp.fluid,
      cols: 2,
      name: 'Clarista J.',
    },
    {
      img: props.data.image50.childImageSharp.fluid,
      cols: 1,
      name: 'Todd W.',
    },
    {
      img: props.data.image51.childImageSharp.fluid,
      cols: 2,
      name: 'Nina R.',
    },
    {
      img: props.data.image52.childImageSharp.fluid,
      cols: 2,
      name: 'Karyn P.',
    },
    {
      img: props.data.image53.childImageSharp.fluid,
      cols: 4,
      name: 'Jai P.',
    },
  ]

  return (
    <Layout>
      <SEO title="Portraits" />
      <h1>Portraits</h1>
      <PortraitGrid data={portraitData} />
    </Layout>
  )
}

export default PortraitsPage

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "portraits/Damon_B.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "portraits/Astro_Three_Times_M.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "portraits/Daniel_T.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image4: file(relativePath: { eq: "portraits/Jim_S.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image5: file(relativePath: { eq: "portraits/Unknown_1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image6: file(relativePath: { eq: "portraits/Jerry_W.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image7: file(relativePath: { eq: "portraits/Unknown_4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image8: file(relativePath: { eq: "portraits/Aziga_W.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image9: file(relativePath: { eq: "portraits/Larissa_T.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image10: file(relativePath: { eq: "portraits/Dan_K.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image11: file(relativePath: { eq: "portraits/Unknown_16.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image12: file(relativePath: { eq: "portraits/Unknown_12.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image13: file(relativePath: { eq: "portraits/Maria_M.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image14: file(relativePath: { eq: "portraits/Cabrina_J.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image15: file(relativePath: { eq: "portraits/Unknown_6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image16: file(relativePath: { eq: "portraits/John_D.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image17: file(relativePath: { eq: "portraits/Unknown_3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image18: file(relativePath: { eq: "portraits/Omar_D.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image19: file(relativePath: { eq: "portraits/Bear_L.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image20: file(relativePath: { eq: "portraits/Unknown_8.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image21: file(relativePath: { eq: "portraits/Keith_M.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image22: file(relativePath: { eq: "portraits/Unknown_15.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image23: file(relativePath: { eq: "portraits/Ken_T.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image24: file(relativePath: { eq: "portraits/Dezsi_G.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image25: file(relativePath: { eq: "portraits/Clifford_M.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image26: file(relativePath: { eq: "portraits/Leroy_B.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image27: file(relativePath: { eq: "portraits/Leah_Z.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image28: file(relativePath: { eq: "portraits/Unknown_9.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image29: file(relativePath: { eq: "portraits/Unknown_10.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image30: file(relativePath: { eq: "portraits/Eric_N.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image31: file(relativePath: { eq: "portraits/Michelle_T.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image32: file(relativePath: { eq: "portraits/Unknown_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image33: file(relativePath: { eq: "portraits/Mona_R.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image34: file(relativePath: { eq: "portraits/Unknown_11.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image35: file(relativePath: { eq: "portraits/Mr_Williams.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image36: file(relativePath: { eq: "portraits/Myron_N.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image37: file(relativePath: { eq: "portraits/Unknown_7.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image38: file(relativePath: { eq: "portraits/Unknown_14.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image39: file(relativePath: { eq: "portraits/Unknown_17.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image40: file(relativePath: { eq: "portraits/Hailey_M.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image41: file(relativePath: { eq: "portraits/Amber_H.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image42: file(relativePath: { eq: "portraits/Corrina_G.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image43: file(relativePath: { eq: "portraits/Brittian_Capt_Jack_S.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image44: file(relativePath: { eq: "portraits/Mrs_Williams.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image45: file(relativePath: { eq: "portraits/Travis_N.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image46: file(relativePath: { eq: "portraits/Unknown_13.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image47: file(relativePath: { eq: "portraits/Justin_A.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image48: file(relativePath: { eq: "portraits/Unknown_5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image49: file(relativePath: { eq: "portraits/Clarista_J.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image50: file(relativePath: { eq: "portraits/Todd_W.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image51: file(relativePath: { eq: "portraits/Nina_R.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image52: file(relativePath: { eq: "portraits/Karyn_P.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image53: file(relativePath: { eq: "portraits/Jai_P.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
