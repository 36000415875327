import React from 'react'
import Img from 'gatsby-image'
import { makeStyles } from '@material-ui/core/styles'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import { isMobile } from 'react-device-detect'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
  },
  gridListTile: {
    width: '100%',
    height: '100%',
  },
}))

const PortraitGrid = ({ data }) => {
  const classes = useStyles()
  return (
    <GridList
      cellHeight={isMobile ? 100 : 200}
      className={classes.gridList}
      cols={4}
    >
      {data.map((tile, i) => (
        <GridListTile
          key={tile.img + i}
          className={classes.gridListTile}
          cols={isMobile ? 2 : tile.cols || 1}
          rows={isMobile ? 2 : tile.cols === 4 ? 3 : tile.cols === 2 ? 2 : 1}
        >
          <Img fluid={tile.img} alt={tile.alt} />
          <GridListTileBar title={tile.name} />
        </GridListTile>
      ))}
    </GridList>
  )
}

export default PortraitGrid
